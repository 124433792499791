import { cloudErrorReporter } from '@gain/modules/cloud-error-reporter'
import axios, { isAxiosError } from 'axios'
import { useSnackbar } from 'notistack'

import { UPLOAD_URL } from './urls'

export function useFileUpload() {
  const { enqueueSnackbar } = useSnackbar()

  return async (file: File, options: { secure?: boolean } = {}): Promise<number | undefined> => {
    try {
      const response = await axios.post<number>(UPLOAD_URL, file, {
        headers: {
          'Content-Type': file.type,
          'X-Filename': encodeURIComponent(file.name),
          'X-Secure': options.secure ? 'true' : 'false',
        },
        withCredentials: true,
      })

      return response.data
    } catch (err) {
      if (isAxiosError(err) && (err.response?.status === 413 || err.response?.status === 400)) {
        // Show alert
        enqueueSnackbar(err.response?.data, {
          key: 'file-upload-error',
          preventDuplicate: true,
          variant: 'error',
        })
      } else {
        // Report the error
        cloudErrorReporter.report(err, {
          reportLocation: {
            functionName: 'useUploadFile',
          },
        })
      }

      return undefined
    }
  }
}
